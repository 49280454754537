<template>
    <div v-on:click="hideModal" class="absolute inset-0 bg-black bg-opacity-70 h-screen w-screen xl:px-32 px-2 py-16 z-50">
        <div class="flex flex-col xl:w-3/5 md:w-10/12 max-h-full bg-white m-auto rounded-lg ">
            <div class="flex flex-row items-center bg-gray-200 h-12 rounded-t-lg px-3 cursor-pointer top-0">
                <div class="flex-none text-xl text-gray-700">{{AdventDay.date}}</div>
                <div class="flex-grow h-16"></div>
                <div v-on:click="hideModal" v-shortkey.once="['esc']" @shortkey="hideModal" class="flex-none text-2xl text-gray-700">&times;</div>
            </div>
            <div v-if="AdventDay.published" class="flex-grow p-10 overflow-auto">
                <div class="text-center text-3xl text-gray-700 font-serif">
                    <h1>{{AdventDay.title}}</h1>
                </div>

                <div class="w-full mt-10">
                    <img :src="AdventDay.image" alt="Test" class="mx-auto max-h-72">
                </div>
                <div class="w-full mt-10 text-lg text-gray-700" v-html="AdventDay.content"></div>
            </div>
            <div v-else class="flex-grow p-10 overflow-auto text-gray-700 text-center">
                <font-awesome-icon :icon="['fas', 'ban']" class="text-8xl"/>
                <div class="text-3xl mt-8">
                    Hier ist noch ein wenig Geduld gefragt...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'AdventDay'
    ],

    methods: {
        hideModal: function () {

            window._paq.push(['trackContentImpression', 'Türchen', this.AdventDay.date, 'https://adventskalender.sanktludwig.de/tuerchen/'+this.AdventDay.date]);
            window._paq.push(['trackContentInteraction', 'Türchen geschlossen', 'Tag', this.AdventDay.date, 'https://adventskalender.sanktludwig.de/tuerchen/'+this.AdventDay.date]);
            
            this.AdventDay.show = false

            if(this.AdventDay.published) {
                this.AdventDay.shown = true
            }
        }
    }
}
</script>