<template>
    <div class="bg-gray-400 bg-opacity-50 mb-12 py-8 px-8 rounded-lg" >
        <div class="w-full text-center font-serif md:text-8xl text-4xl text-white pb-8">
            {{ title }}
        </div>
        <div class="grid justify-evenly 2xl:grid-cols-8 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 gap-x-8 gap-y-6">
            <div v-for="day in days" v-bind:key="day.id">
                <advent-day :day="day"></advent-day>    
            </div>
        </div>
    </div>
</template>

<script>
import AdventDay from './AdventCalendar/AdventDay.vue'
const axios = require('axios').default;

export default {
    components: {
        AdventDay
    },
    
    props: {
        title: {
            type: String,
            default: 'Dezember',
        },
        href: {
            type: String
        },
        mix: {
            type: Boolean,
            default: true,
        }
    },

    data: function() {
        return {
            days: [],
            displayModal: {}
        }
    },

    mounted: function () {
        
        this.fetchData()
    },

    methods: {

        fetchData: function() {

            let vm = this

            axios.get(vm.href)
                .then(function (response) {
                    // handle success
                    vm.processData(response.data)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
                .then(function () {
                    // always executed
                })
        },

        processData: function(data) {
            
            let vm = this

            if(vm.mix) {
                if(!localStorage[vm.title+'_randomised'] || JSON.parse(localStorage[vm.title+'_randomised']).length != data.length) {
                    let idArray = []

                    data.forEach(function(item, index) {
                        idArray.push(index)
                    })

                    idArray = vm.shuffleArray(idArray)

                    localStorage.setItem(vm.title+'_randomised', JSON.stringify(idArray))
                }

                let idArray = JSON.parse(localStorage[vm.title+'_randomised'])

                let tmpArray = []

                for (let i = 0; i < idArray.length; i++) {
                    tmpArray.push(data[idArray[i]])
                }

                vm.days = tmpArray
            }else{
                vm.days = data
            }
        },

        shuffleArray: function (array) {
            let curId = array.length;
            // There remain elements to shuffle
            while (0 !== curId) {
                // Pick a remaining element
                let randId = Math.floor(Math.random() * curId);
                curId -= 1;
                // Swap it with the current element.
                let tmp = array[curId];
                array[curId] = array[randId];
                array[randId] = tmp;
            }
            return array;
        }
    }
}
</script>

<style>
    .modal-slide-enter-active {
        transition: all .4s ease-in;
    }
    .modal-slide-leave-active {
        transition: all .4s ease-out;
    }
    .modal-slide-enter, .modal-slide-leave-to{
        opacity: 0;
    }
</style>