<template>
  <div id="app" class="bg-fixed bg-no-repeat bg-cover bg-center h-screen w-screen">
    <div class="h-full w-full overflow-scroll xl:px-32 px-0 xl:py-12">
      <advent-calendar title="November" href="https://adventskalender.sanktludwig.de/datasource.php?month=november" :mix="false"></advent-calendar>
      <advent-calendar title="Dezember" href="https://adventskalender.sanktludwig.de/datasource.php?month=december"></advent-calendar>
      <div class="flex flex-row-reverse flex-wrap-reverse items-center py-4 px-2">
        <div @click="resetApp" class="border-lg bg-white bg-opacity-75 hover:bg-opacity-100 rounded text-center mx-3 px-3 py-1 cursor-pointer">
          <font-awesome-icon :icon="['fas', 'sync-alt']"/>
          Reset
        </div>
        <div class="border-lg bg-white bg-opacity-75 hover:bg-opacity-100 rounded text-center mx-3 px-3 py-1 my-4 cursor-pointer">
          <a href="https://www.sanktludwig.de" target="_blank">Website von Sankt Ludwig</a>
        </div>
        <div class="mx-3 cursor-pointer underline text-white my-4">
          <a href="https://www.sanktludwig.de/impressum/" target="_blank">Impressum</a>
        </div>
        <div class="mx-3 cursor-pointer underline text-white my-4">
          <a href="https://www.sanktludwig.de/datenschutz/" target="_blank">Datenschutz</a>
        </div>
      </div>
      
    </div>
    <cookie-law theme="base" buttonText="OK, verstanden!">
      <div slot="message">
        Dieser Adventskalender verwendet Cookies und den Browserspeicher, um sich z.B. zu merken, welche Türchen bereits geöffnet wurden.
        Zudem zählen wir unsere Besucher. Mehr Informationen gibt es unter <a href="https://www.sanktludwig.de/datenschutz/" target="_blank" class="underline">Datenschutz</a>.
      </div>
    </cookie-law>
  </div>
</template>

<script>
import AdventCalendar from './components/AdventCalendar.vue'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  components: {
    AdventCalendar,
    CookieLaw
  },
  methods: {
      resetApp: function() {
        window._paq.push(['trackContentImpression', 'Kalender', 'Startansicht', 'https://adventskalender.sanktludwig.de/']);
        window._paq.push(['trackContentInteraction', 'Reload durchgeführt', 'Kalender', 'Startansicht', 'https://adventskalender.sanktludwig.de/']);
        
        localStorage.clear()
        window.location.reload(true)
      }
  }
}
</script>

<style>

  #app {
    background-image: url('assets/Advent.jpeg');
  }
</style>
