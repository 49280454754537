<template>
    <div class="grid justify-self-center grid-cols-1 h-48 w-36 bg-red-600 rounded-lg bg-local bg-no-repeat bg-cover bg-center" :style="backgroundImage">
        <div class="place-self-center z-10">
            <div @click="showModal" class="rounded-full h-24 w-24 flex items-center justify-center bg-white hover:animate-pulse text-3xl font-serif text-gray-700 border-gray-700 cursor-pointer" :class="{border: day.shown}">
                {{ day.id }}
            </div>
        </div>
        <transition name="modal-slide">
            <day-modal v-if="day.show" v-bind:advent-day="day"></day-modal>
        </transition>
    </div>
</template>

<script>
import DayModal from './DayModal'

export default {
    components: {
        DayModal
    },
    
    props: [
        'day'
    ],

    computed: {
        backgroundImage: function () {
            if (this.day.shown) {
                return {
                    backgroundImage: "url('"+this.day.image+"')"
                }
            } else {
                return {}
            }
            
        }
    },

    mounted: function () {
        if(this.wasShownBefore()){
            this.day.shown = true
        }
        
    },

    methods: {
        showModal: function () {
            this.day.show = true
            if(this.day.published) {
                localStorage.setItem(this.day.date, "true")
            }
            window._paq.push(['trackContentImpression', 'Türchen', this.day.date, 'https://adventskalender.sanktludwig.de/tuerchen/'+this.day.date]);
            window._paq.push(['trackContentInteraction', 'Türchen geöffnet', 'Zahl', this.day.date, 'https://adventskalender.sanktludwig.de/tuerchen/'+this.day.date]);
        },

        wasShownBefore: function() {
            let date = this.day.date
            if(localStorage[date] && localStorage[date] == "true") {
                return true
            }else{
                return false
            }
        }
    }
}
</script>